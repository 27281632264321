import React from "react"
import styled from "@emotion/styled"
import { keyframes } from "@emotion/react"

const load = keyframes`
0% {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}
100% {
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
}
`

const ProcessingWaiter = styled.div`
  .cssload-container {
    width: 100%;
    height: 49px;
    text-align: center;
  }

  .cssload-double-torus {
    width: 49px;
    height: 49px;
    margin: 0 auto;
    border: 4px solid ${(props) => (props.color ? props.color : "#00003a")};
    border-radius: 50%;
    border-color: ${(props) => (props.color ? props.color : "transparent")}
      rgba(0, 0, 0, 0.9) rgba(0, 0, 0, 0.9);
    animation: ${load} 1000ms infinite linear;
    -o-animation: ${load} 1000ms infinite linear;
    -ms-animation: ${load} 1000ms infinite linear;
    -webkit-animation: ${load} 1000ms infinite linear;
    -moz-animation: ${load} 1000ms infinite linear;
  }
`

const Spinner = ({ color }) => (
  <ProcessingWaiter color={color}>
    <div className="cssload-container">
      <div className="cssload-double-torus" />
    </div>
  </ProcessingWaiter>
)

export default Spinner
