export function formatCartItemPrice(cart, cartItem) {
  const variantPrice = getVariantPrice(cart, cartItem.variant)
  const currencyCode = cart.currencyCode || cart.currency_code

  return formatMoneyAmount({
    currencyCode,
    amount: variantPrice * cartItem.quantity,
  })
}

function getTaxRate(cart) {
  if ("taxRate" in cart) {
    return cart.taxRate / 100
  } else if ("tax_rate" in cart) {
    return cart.tax_rate / 100
  } else if (cart.region) {
    return cart.region && cart.region.tax_rate / 100
  }
  return 0.25
}

export function getVariantPrice(cart, variant) {
  const currencyCode = cart.currencyCode || cart.currency_code
  let taxRate = getTaxRate(cart)
  let moneyAmount = variant.prices.find(
    (p) => p.currency_code.toLowerCase() === currencyCode.toLowerCase()
  )

  if (moneyAmount && moneyAmount.amount) {
    return moneyAmount.amount * (1 + taxRate)
  }

  return undefined
}

export function getVariantSalePrice(cart, variant) {
  const currencyCode = cart.currencyCode || cart.currency_code
  let taxRate = getTaxRate(cart)
  let moneyAmount = variant.prices.find(
    (p) => p.currency_code.toLowerCase() === currencyCode.toLowerCase()
  )

  if (moneyAmount && moneyAmount.sale_amount) {
    return moneyAmount.sale_amount * (1 + taxRate)
  }

  return undefined
}

export function formatMoneyAmount(moneyAmount, digits, taxRate = 0) {
  let locale = "en-US"

  // We need this to display 'Kr' instead of 'DKK'
  if (moneyAmount.currencyCode.toLowerCase() === "dkk") {
    locale = "da-DK"
  }

  const currCode = currencyCodeToSymbol(moneyAmount.currencyCode)

  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency: moneyAmount.currencyCode,
    minimumFractionDigits: digits,
  }).format((moneyAmount.amount * (1 + taxRate / 100)) / 100)
}

export function formatPrices(cart, prices, digits = 2) {
  let amount = prices
  if (typeof prices === "object") {
    amount = prices.find(
      (p) => p.currencyCode.toLowerCase() === currencyCode.toLowerCase()
    )
  }
  const taxRate = getTaxRate(cart)
  const currencyCode = cart.currencyCode || cart.currency_code
  return formatMoneyAmount(
    {
      currencyCode,
      amount: amount * (1 + taxRate),
    },
    digits
  )
}

export function formatVariantSalePrice(cart, variant, digits = 2) {
  const currencyCode = cart.currency || cart.currencyCode
  return formatMoneyAmount(
    {
      currencyCode,
      amount: getVariantSalePrice(cart, variant),
    },
    digits
  )
}

export function formatVariantPrice(cart, variant, digits = 2) {
  const currencyCode = cart.currency || cart.currencyCode
  return formatMoneyAmount(
    {
      currencyCode,
      amount: getVariantPrice(cart, variant),
    },
    digits
  )
}

export function formatCartShippingTotal(cart, includeTax = true) {
  let shippingTotal =
    "shipping_total" in cart ? cart.shipping_total : cart.shippingTotal
  if (includeTax) {
    const taxRate = getTaxRate(cart)
    shippingTotal += shippingTotal * taxRate
  }

  const currencyCode = cart.currencyCode || cart.currency_code
  return formatMoneyAmount({
    currencyCode,
    amount: shippingTotal,
  })
}

export function formatCartSubtotal(cart, includeTax = true) {
  let subtotal = cart.subtotal
  if (includeTax) {
    const taxRate = getTaxRate(cart)
    subtotal += subtotal * taxRate
  }

  const currencyCode = cart.currencyCode || cart.currency_code
  return formatMoneyAmount({
    currencyCode,
    amount: subtotal,
  })
}

export function formatVatDeduction(cart) {
  let subtotal = cart.subtotal || cart.subTotal

  const currencyCode = cart.currencyCode || cart.currency_code
  return formatMoneyAmount({
    currencyCode,
    amount: subtotal * 0.25,
  })
}

export function formatCartTotal(cart, allowNegative = true) {
  const currencyCode = cart.currencyCode || cart.currency_code
  let amount = cart.total
  if (!allowNegative && cart.total < 0) {
    amount *= -1
  }
  return formatMoneyAmount({
    currencyCode,
    amount,
  })
}

export function formatCartGiftCard(cart) {
  const giftCardTotal =
    "gift_card_total" in cart ? cart.gift_card_total : cart.giftCardTotal
  const currencyCode = cart.currencyCode || cart.currency_code
  const taxRate = getTaxRate(cart)
  return formatMoneyAmount({
    currencyCode,
    amount: giftCardTotal * (1 + taxRate),
  })
}

export function formatCartDiscount(cart) {
  const discountTotal =
    "discount_total" in cart ? cart.discount_total : cart.discountTotal
  const currencyCode = cart.currencyCode || cart.currency_code
  const taxRate = getTaxRate(cart)
  return formatMoneyAmount({
    currencyCode,
    amount: discountTotal * (1 + taxRate),
  })
}

export function formatShipping(amount, cart) {
  const currencyCode = cart.currencyCode || cart.currency_code
  const taxRate = getTaxRate(cart)

  return formatMoneyAmount({
    currencyCode,
    amount: amount * (1 + taxRate),
  })
}

export function formatOrderDiscount(order) {
  const currencyCode = order.currencyCode || order.currency_code
  const taxRate = getOrderTaxRate(order)
  return formatMoneyAmount({
    currencyCode,
    amount: order.discount_total * (1 + taxRate),
  })
}

function getOrderTaxRate(order) {
  if ("tax_rate" in order) {
    return order.tax_rate / 100
  } else if (order.region) {
    return order.region && order.region.tax_rate / 100
  }
  return 0.25
}

export function formatOrderShipping(price, order) {
  const currencyCode = order.currencyCode || order.currency_code
  const taxRate = getOrderTaxRate(order)
  return formatMoneyAmount({
    currencyCode,
    amount: (price += price * taxRate),
  })
}

export function currencyCodeToSymbol(currencyCode) {
  switch (currencyCode) {
    case "DKK":
      return "Kr."
    case "EUR":
      return "€"
    case "USD":
      return "$"
    case "GBP":
      return "£"
    default:
      return ""
  }
}
